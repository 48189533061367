<script setup lang="ts">
  import { MpSelect } from '@borg/ui';
  import { isString, objectKeys } from '@borg/utils';
  import { SelectOption } from '@borg/types';

  const props = withDefaults(
    defineProps<{
      fullName?: boolean;
    }>(),
    {
      fullName: true,
    },
  );

  const i18n = useI18n();
  const config = useProjectConfig();
  const switchLocalePath = useSwitchLocalePath();
  const variant = computed(() => (props.fullName ? 'ghost' : 'rounded'));
  const showLanguageSelector = computed(() => options.value.length > 1);

  const supportedLocaleCodes = config.languages.map((language) => language.id);

  const supportedLocales = supportedLocaleCodes
    ? [...i18n.locales.value].filter((locale) => supportedLocaleCodes?.includes(locale.code))
    : [...i18n.locales.value];

  const languageCodeNamePairs = supportedLocales.reduce<Record<string, string>>(
    (acc, locale) =>
      isString(locale)
        ? { ...acc, [locale]: locale }
        : {
            ...acc,
            [locale.code]: props.fullName
              ? locale.name || locale.code?.toUpperCase()
              : locale.code?.toUpperCase(),
          },
    {},
  );

  const selected = ref<SelectOption>({
    value: i18n.locale.value,
    label: languageCodeNamePairs[i18n.locale.value],
  });

  const options = computed<SelectOption[]>(() =>
    objectKeys(languageCodeNamePairs).map((code) => ({
      value: code,
      label: languageCodeNamePairs[code],
    })),
  );

  function onChange(option: SelectOption) {
    navigate(switchLocalePath(option.value), { translate: false });
  }
</script>

<template>
  <MpSelect
    v-if="showLanguageSelector"
    v-model="selected"
    data-test="language-switch"
    :placeholder="$t('LANGUAGE_SWITCH.CHANGE_LANGUAGE')"
    name="language"
    :class="{
      'language-switch': true,
      'language-switch--mini': !fullName,
    }"
    :options="options"
    :unselectable="false"
    :variant="variant"
    :size="fullName ? 'md' : 'sm'"
    @update:model-value="onChange"
  />
</template>

<style scoped lang="scss">
  .language-switch {
    :deep(.mp-combobox) {
      @include dark-mode {
        --input-icons-color: var(--mp-color-grey-100);

        @include screen-lg {
          background-color: var(--mp-color-surface-50);
          --input-border-color: var(--mp-color-grey-100);
        }
      }
    }

    &--mini {
      :deep(.arrow) {
        padding-left: var(--mp-space-40);
      }

      :deep(.selectable__text) {
        position: initial;
      }

      :deep(.mp-icon) {
        --icon-size: 1rem;
      }
    }
  }
</style>
