<template>
  <div class="hero">
    <BrandImage />
    <JobSearchBar />
    <SiteLinks />
  </div>
</template>

<style scoped lang="scss">
  .hero {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 4;

    padding-top: var(--mp-space-60);
    padding-bottom: 0;
    padding-left: var(--site-content-horizontal-padding);
    padding-right: var(--site-content-horizontal-padding);

    background-color: var(--mp-color-surface-50);

    @include screen-md {
      padding-top: var(--mp-space-90);
    }
  }
</style>
