<template>
  <div class="brand-image">
    <Illustration
      image="cover"
      height="140"
    />
  </div>
</template>

<style scoped lang="scss">
  .brand-image {
    width: fit-content;
    max-width: 100%;
    margin-bottom: var(--mp-space-70);

    @include screen-md {
      margin-bottom: var(--mp-space-80);
    }
  }
</style>
