<script setup lang="ts">
  import { MpText } from '@borg/ui';

  const { data: links } = await siteLinksRepository.getMany();
</script>

<template>
  <div
    v-if="links?.length"
    class="hot-search"
  >
    <div class="hot-search__links">
      <LinksLocale
        v-for="link in links"
        :key="link.url"
        class="hot-search__link"
        :to="link.url"
        :target="link.url[0] === '/' ? undefined : '_blank'"
      >
        <img
          v-if="link.iconUrl"
          class="hot-search__icon"
          :src="link.iconUrl"
          :alt="link.title"
        />
        <MpText no-margin>
          {{ link.title }}
        </MpText>
      </LinksLocale>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .hot-search {
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-top: var(--site-hero-content-top-margin);

    /* hide scrollbar */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }

    &__links {
      display: flex;
      gap: var(--mp-space-70);
      width: max-content;
    }

    &__link {
      display: flex;
      align-items: center;
      gap: var(--mp-space-30);
      color: var(--mp-color-text-05);
      margin: 2px;
    }

    &__icon {
      max-height: var(--mp-space-40);
    }
  }
</style>
